import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Seo from '@gtg/components/seo'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ArticleLayout from '../components/articleLayout'
import Category from '../components/category'
import GlobalWrapper from '../components/globalWrapper'
import Subtitle from '../components/subtitle'
import Img1Src from '../images/articles/lenscleaning/1.jpg'
import Img2Src from '../images/articles/lenscleaning/2.png'
import Img3Src from '../images/articles/lenscleaning/3.jpg'
import Img4Src from '../images/articles/lenscleaning/4.jpg'
import Img5Src from '../images/articles/lenscleaning/5.png'
import { color } from '../styles/theme'

const BottomBox = styled(Box)`
  background: ${color.coal50};
  border-radius: 10px;
  box-shadow: 0px 10px 40px #e3ddd4;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const BoxImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 500px;
`

const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Lenzbox Wissen, Ratgeber</Category>
            <hr />
            <h1>Kontaktlinsen reinigen</h1>
            <Subtitle>So geht die Kontaktlinsenpflege richtig</Subtitle>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img1Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <p>
              Für die sichere Anwendung und einen gleichbleibenden Tragekomfort
              deiner Kontaktlinsen ist es entscheidend, dass du deine
              Kontaktlinsen pflegst – und zwar gründlich.
            </p>
            <p>
              Die Kontaktlinsenpflege ist wichtig, da sich auf der
              Kontaktlinsenoberfläche natürlicherweise Staubpartikel,
              Schmutzpartikel sowie Proteinrückstände sammeln und Fettrückstände
              bilden. Um diese effektiv zu entfernen ist es wichtig, dass du
              deine Kontaktlinsen richtig reinigst.
            </p>
            <p>
              Wie du deine Kontaktlinsen pflegen solltest, worauf du bei der
              Pflege achten solltest und welche Schritte zur Kontaktlinsenpflege
              gehören, das zeigen wir dir im folgenden Artikel.
            </p>
            <h2>Warum die richtige Pflege so wichtig ist</h2>
            <p>
              Das Auge ist eines unserer wichtigsten Sinnesorgane. Im Gegensatz
              zur Brille werden Kontaktlinsen direkt auf dem Auge getragen. Aus
              diesem Grund ist es wichtig, dass du deine Kontaktlinsen{' '}
              <a
                href="https://www.apotheken-umschau.de/mein-koerper/augen/sehhilfen-linsen-in-allen-lebenslagen-722341.html"
                target="_blank"
                rel="noreferrer"
              >
                regelmäßig pflegst und sorgfältig reinigst
              </a>
              .
            </p>
            <p>
              Das Auge ist sehr empfindlich. Eine halbherzige
              Kontaktlinsenpflege kann zu Ablagerungen führen, die Infektionen
              im Auge hervorrufen können. Daher sind Sorgfalt, die richtigen
              Reinigungs- und Aufbewahrungsmittel sowie sorgfältiges Einhalten
              aller Schritte bei der Reinigung gefragt.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img2Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Kontaktlinsenpflege: Darauf solltest du achten</h2>
            <p>
              Es gibt einige Punkte, auf die du achten solltest, wenn du deine
              Kontaktlinsen pflegst. Im Folgenden zeigen wir dir einige Regeln,
              mit denen du deine Kontaktlinsen gründlich pflegen kannst und die
              Hygiene bei der Kontaktlinsenpflege garantieren.
            </p>
            <p>
              <b>Hände waschen:</b> Bevor du deine Kontaktlinsen anfasst,
              solltest du deine Hände gründlich waschen. Nach dem Waschen
              solltest du deine Hände mit einem fusselfreien Handtuch
              abtrocknen. Kontaktlinsen dürfen immer nur mit sauberen,
              seifenfreien und fettfreien Händen angefasst werden.
            </p>
            <p>
              <b>Niemals Leitungswasser verwenden:</b> Verwende niemals
              Leitungswasser in Verbindung mit Kontaktlinsen. Leitungswasser ist
              nicht keimfrei und enthält Chlor, Mineralstoffe und
              Metallpartikel, welche die Kontaktlinsen beschädigen können und im
              schlimmsten Fall auch schlecht für deine Augen sein können. So
              kann es bei Kontakt mit unsauberen Kontaktlinsen beispielsweise zu
              Augeninfektionen kommen.
            </p>
            <p>
              <b>Flüssigkeiten wechseln:</b> Die Flüssigkeit zur
              Kontaktlinsenpflege ist nur für die einmalige Benutzung. Achte
              darauf, dass keine gebrauchte Flüssigkeit im Aufbewahrungsbehälter
              verbleibt.
            </p>
            <p>
              <b>Aufbewahrungsbehälter reinigen:</b> Der herkömmliche
              Aufbewahrungsbehälter sollte täglich mit Reinigungsflüssigkeit
              gereinigt werden. Nach spätestens drei Monaten Benutzung sollte
              der Aufbewahrungsbehälter durch eine neue Linsenbox ersetzt
              werden.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img3Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Kontaktlinsen richtig reinigen - Schritt für Schritt</h2>
            <p>
              Achte auf alle vorangegangenen Regeln der Hygiene während du deine
              Kontaktlinsen reinigst. Mit der folgenden Anleitung kannst du bei
              deiner Kontaktlinsenpflege nichts mehr falsch machen.
            </p>
            <p>
              So reinigst du deine Kontaktlinsen Schritt für Schritt und erhöhst
              so den Tragekomfort, die Lebensdauer der Linsen und stellst eine
              korrekte Reinigung sicher:
            </p>
            <h3>Schritt 1: Reinigung der Oberfläche</h3>
            <p>
              Der erste Schritt der Kontaktlinsenpflege ist die Reinigung der
              Oberfläche der Linse. Das passiert am besten in der Handfläche.
              Reibe unter Hinzugabe eines Reinigers oder einer
              All-in-One-Flüssigkeit die Kontaktlinsen vorsichtig zwischen den
              Fingerkuppen. Durch diesen Schritt wird das Lösen der Partikel von
              der Oberfläche der Linse erleichtert. Bei diesem Schritt der
              Kontaktlinsenpflege ist Vorsicht geboten, um die Linsen nicht beim
              Reiben zwischen den Fingerkuppen zu beschädigen.
            </p>
            <h3>Schritt 2: Abspülen der Linse</h3>
            <p>
              Im zweiten Schritt wird der gelöste Schmutz mithilfe einer
              All-in-One-Lösung oder einer Kochsalzlösung abgespült. Solltest du
              sensible Augen haben, ist eine Kochsalzlösung ohne
              Konservierungsstoffe die richtige Wahl, wenn du deine
              Kontaktlinsen pflegst.
            </p>
            <h3>Schritt 3: Desinfektion</h3>
            <p>
              Um alle Mikroorganismen zu beseitigen, ist neben dem Reiben
              zwischen den Fingerkuppen die Desinfektion mit einer
              All-in-One-Flüssigkeit oder einer Peroxidlösung notwendig. Die
              Desinfektion der Kontaktlinsen benötigt einige Zeit, weshalb es
              sich empfiehlt, die Linsen über Nacht in der entsprechenden Lösung
              aufzubewahren. Hierfür gibt es spezielle Behälter, in denen die
              Kontaktlinsen sicher aufbewahrt werden können.
            </p>
            <h3>Schritt 4: Neutralisation</h3>
            <p>
              Die Neutralisation ist nur dann erforderlich, wenn du aufgrund von
              Allergien oder besonders empfindlichen Augen Peroxidlösung zur
              Desinfektion der Kontaktlinsen nutzt. Die Peroxidlösung darf unter
              keinen Umständen in dein Auge gelangen und muss daher
              neutralisiert werden. Um Rückstände der Lösung abzuspülen, kann
              eine Kochsalzlösung verwendet werden. Achte auch vor dem erneuten
              Einsetzen deiner Kontaktlinsen auf{' '}
              <a
                href="https://www.infektionsschutz.de/haendewaschen.html"
                target="_blank"
                rel="noreferrer"
              >
                Handhygiene
              </a>
              .
            </p>
            <h3>Zusatz: Proteinentferner</h3>
            <p>
              Als zusätzlichen Schritt kannst du wöchentlich Proteinentferner
              zur Entfernung von{' '}
              <a
                href="https://www.sehen.de/service/glossar/proteinentfernung/"
                target="_blank"
                rel="noreferrer"
              >
                Proteinablagerungen
              </a>{' '}
              nutzen. Besonders hartnäckige Eiweißablagerungen werden von
              Enzymen zersetzt und entfernt.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img4Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Welche Kontaktlinsen-Pflegemittel gibt es?</h2>
            <p>
              Auf dem Markt gibt es viele verschiedene{' '}
              <a
                href="https://www.test.de/Kontaktlinsenpflegemittel-Acht-desinfizieren-schlecht-1854572-2854572/"
                target="_blank"
                rel="noreferrer"
              >
                Kontaktlinsen-Pflegemittel
              </a>
              , welche unterschiedliche Eigenschaften und Vorteile haben und auf
              den jeweiligen Linsentypen angepasst sind. Für{' '}
              <a
                href="https://www.kontaktlinseninfo.de/kontaktlinsenarten/tageslinsen/"
                target="_blank"
                rel="noreferrer"
              >
                Tageslinsen
              </a>{' '}
              gibt es keine Pflegemittel, da diese nach dem Tragen entsorgt
              werden und nur für den einmaligen Gebrauch vorgesehen sind.
            </p>
            <p>
              Für die Reinigung von Monatslinsen hast du die Wahl zwischen
              speziellem Kontaktlinsen-Pflegemittel für jeden einzelnen Schritt
              der Kontaktlinsenpflege (Reinigen, Abspülen, Desinfizieren,
              Neutralisieren) oder All-in-One-Komplettlösungen, welche alle
              Schritte vereinen.
            </p>
            <p>
              Welches Kontaktlinsen-Pflegemittel für dich geeignet ist, richtet
              sich auch nach deinem Trageverhalten. Solltest du beispielsweise
              nur einmal in der Woche Kontaktlinsen tragen, sind für dich andere
              Pflegemittel geeignet als für den täglichen Nutzer von
              Kontaktlinsen.
            </p>
            <p>
              Achte außerdem darauf, welchen{' '}
              <a
                href="https://www.misterspex.de/kontaktlinsen-ratgeber/weiche-und-harte-kontaktlinsen-im-vergleich"
                target="_blank"
                rel="noreferrer"
              >
                Linsentyp
              </a>{' '}
              du verwendest. Für weiche Kontaktlinsen sind andere
              Kontaktlinsen-Pflegemittel sinnvoll als für harte (formstabile)
              Kontaktlinsen. Unabhängig vom Kontaktlinsen-Pflegemittel solltest
              du immer auf das Haltbarkeitsdatum der verwendeten Produkte
              achten.
            </p>
            <p>
              Ein weiterer wichtiger Punkt sind deine individuellen Bedürfnisse.
              Hast du etwa Allergien oder andere Unverträglichkeiten, solltest
              du dich von einem Optiker oder Arzt beraten lassen, um jegliche
              Risiken auszuschließen, wenn du deine Kontaktlinsen pflegst.
            </p>
            <h3>Einzellösungen für jeden Schritt der Kontaktlinsenpflege</h3>
            <p>
              Solltest du sensible Augen haben und insbesondere
              All-in-One-Lösungen nicht vertragen, dann kann eine{' '}
              <a
                href="https://www.321linsen.de/blog/peroxid-loesungen.html"
                target="_blank"
                rel="noreferrer"
              >
                Peroxidlösung
              </a>{' '}
              die richtige Wahl sein. Peroxidlösungen sind zwar von Vorteil, da
              sie meistens keine Konservierungsstoffe beinhalten, die
              Kontaktlinsen müssen allerdings im Nachgang mit einer{' '}
              <a
                href="https://www.blickcheck.de/sehhilfen/kontaktlinsen/pflege-und-reinigung/kontaktlinsenfluessigkeit/kochsalzloesung/"
                target="_blank"
                rel="noreferrer"
              >
                Kochsalzlösung
              </a>{' '}
              neutralisiert und abgespült werden. Peroxid darf keinesfalls ins
              Auge gelangen. Eine ordnungsgemäße Anwendung ist daher sehr
              wichtig.
            </p>
            <p>
              Darüber hinaus kann die Kontaktlinsenpflege durch den Einsatz von{' '}
              <a
                href="https://www.kontaktlinsen.net/wozu-braucht-man-einen-proteinentferner/"
                target="_blank"
                rel="noreferrer"
              >
                Proteinreinigern
              </a>{' '}
              unterstützt werden. Dieser wird unabhängig von der normalen
              Kontaktlinsenpflege angewendet und löst Eiweiße und besonders
              starke Ablagerungen auf deinen Kontaktlinsen.
            </p>
            <h3>Die All-in-One Lösung zur Kontaktlinsenreinigung</h3>
            <p>
              Kontaktlinsen reinigen ist mit der All-in-One-Flüssigkeit am
              einfachsten, da die Flüssigkeit sowohl reinigen, abspülen,
              desinfizieren und neutralisieren kann und somit alle Schritte der
              Kontaktlinsenreinigung abdeckt. Das garantiert eine sichere
              Anwendung. Nachteilig ist, dass All-in-One-Flüssigkeiten bei
              Allergikern zu Unverträglichkeiten führen können und bei sehr
              starken Verschmutzungen der Kontaktlinsen an Grenzen stoßen.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Center>
            <BoxImg src={Img5Src} />
          </Center>
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>
              Kontaktlinsenbehälter zur sicheren Aufbewahrung und Reinigung
            </h2>
            <p>
              Wenn du deine Kontaktlinsen einmal nicht im Auge hast, müssen
              diese stets in Flüssigkeit gelagert werden.
            </p>
            <p>
              Zur Aufbewahrung bieten sich spezielle Kontaktlinsenbehälter an.
              Dort sind sie geschützt vor äußeren Einflüssen und können sicher
              transportiert werden. Gib ausreichend
              Kontaktlinsenreinigungsflüssigkeit in den Behälter. Einfache
              Behälter bestehen aus zwei Plastikkammern für die jeweiligen
              Kontaktlinsen, in welche die gereinigten Linsen und
              Aufbewahrungsflüssigkeit gegeben werden.
            </p>
            <p>
              Komfortabler sind Lösungen wie die demnächst erhältliche Lenzbox.
              Diese innovative All-in-One-Lösung dient nicht nur der
              Aufbewahrung, sondern auch der Pflege der Kontaktlinsen. Die
              reinigende Flüssigkeit pflegt deine Linsen automatisch sicher und
              bequem auf Knopfdruck. Mit der Lenzbox ist eine sichere Mitnahme
              der Kontaktlinsen und die Pflege der Kontaktlinsen komfortabel und
              spontan möglich. Die neuen Kontaktlinsenbehälter von{' '}
              <Link to="/">Lenzbox</Link> sind in 2022 erhältlich.
            </p>
          </Box>
        </Container>
        <Box px={0} pt={0}>
          <Container wMax="md">
            <BottomBox pxMax="xl" pyMax="lg">
              <h2>Die wichtigsten Tipps auf einen Blick</h2>
              <p>
                Die wichtigsten Tipps, die du kennen solltest, wenn du deine
                Kontaktlinsen pflegst, haben wir für dich zusammengefasst. So
                kannst du deine Kontaktlinsen richtig pflegen:
              </p>
              <ul>
                <li>Niemals Wasser für die Kontaktlinsenpflege verwenden</li>
                <li>
                  Haltbarkeitsdatum der Produkte, mit welchen du deine
                  Kontaktlinsen pflegst, beachten
                </li>
                <li>Beachte alle Schritte der Kontaktlinsenpflege</li>
                <li>
                  Peroxidlösung darf niemals mit deinen Augen in Kontakt kommen
                </li>
                <li>
                  Lass dich im Zweifel von einem Optiker oder Arzt beraten
                </li>
                <li>
                  Heutige Aufbewahrungsbehälter können bei der Aufbewahrung und
                  Pflege helfen, müssen jedoch genauso wie deine Kontaktlinsen
                  regelmäßig gepflegt und ausgetauscht werden
                </li>
                <li>
                  Das zukünftige Zuhause deiner Kontaktlinsen könnte eine
                  All-in-One-Lösung von <Link to="/">Lenzbox</Link> sein, die
                  sowohl beim Transport als auch bei der Pflege deiner
                  Kontaktlinsen komfortabel und sicher benutzbar ist
                </li>
              </ul>
            </BottomBox>
          </Container>
        </Box>
      </ArticleLayout>
    </GlobalWrapper>
  )
}

export default ArticlePage
